.app__navbar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    position: fixed;
    z-index: 2;
    background: rgba(255,255,255,0.2);
    
}
/* .app__flex div{
    width: 5px;
    height: 5px;
    border-radius: 50%;

  

} */

.app__navbar-logo img{
    width: 120px;
   
   
}

.app__navbar-links{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

}


.app__navbar-links li{
    margin: 0 1rem;
display: flex;
flex-direction: column;
}

.app__navbar-links a{
    text-decoration: none;
    color: var(--gray-color);
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

.app__navbar-links a:hover {
    color:var( --secondary-color) ;
}

.app__navbar-menu .lines{
  display: none;

}


@media(max-width:900px){
    .app__navbar-links{
        display: none;
    }
    .app__navbar-menu .lines{
        width: 35px;
        height: 35px;
        color: #fff;
        background: var(--secondary-color);
        border-radius: 50%;
        cursor: pointer;
        display: block;
    }
    .app__navbar-menu .side__bar{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        padding: 1rem;
        width: 80%;
       height: 100vh;
       background: url(../../assets/bgWhite.png);
       background-size: cover;
       background-color: var(--white-color);
       background-repeat: no-repeat;
       display: flex;
       align-items: flex-end;
       justify-content: flex-end;
       flex-direction: column;

    }
    .side__bar .cross{
        width: 35px;
        height: 35px;
        color: #fff;
        background: var(--secondary-color);
        border-radius: 50%;
        cursor: pointer;
        margin: 0.5rem 1rem;
    }
    .side__bar ul{
        width: 100%;
        height: 100%;
        list-style: none;
      padding: 0;
      margin: 0;
    }
    .side__bar ul li{
       margin: 1rem;
    }
    .side__bar ul li a{
       text-decoration: none;
       text-transform: uppercase;
       font-size: 1rem;
       color: var(--gray-color);
       font-weight: 500;
       transition: all 0.3s ease-in-out;
     }
     .side__bar ul li a:hover{
        color: var(--secondary-color);
     }

}

@media (max-width:500px) {
    .app__navbar-menu .side__bar{
      
        width: 100%;
       height: 100vh;
   

    }
}