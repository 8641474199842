.app__testimonial-item {
width: 80%;
min-height: 320px;

background-color: var(--white-color);
border-radius: 15px;
transition: all 0.3s ease-in-out;
display: flex;
padding: 1rem;
justify-content: center;
align-items: center;
}

.app__testimonial-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   gap: 3rem;
 
}

.app__testimonial-content p{
    font-size: 1rem;
    font-weight: 500;
}
.app__testimonial-content h4{
   color: var(--secondary-color);
   font-size: 1.2rem;
}
.app__testimonial-item img{
    width: 100px;
    border-radius: 50%;
    height: 100px;
    object-fit: cover;
}

.app__testimonils-btns div{
    width: 50px;
    height: 50px;
    background-color: var(--white-color);
    margin: 1rem 1rem;
    border-radius: 50%;
cursor: pointer;
}

.app__testimonils-btns div svg{
    color: var(--secondary-color);
    font-size: 1.3rem;
}


.app__testimonils-btns div svg:hover{
    transform: scale(2);
}






.app__testimonials-brands{
    width: 100%;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.app__testimonials-brands div{
    width: 150px;
    margin: 1.5em;
}

.app__testimonials-brands div img{
    width: 100%;
    height: auto;
    filter: grayscale(1);
    object-fit: cover;
}

.app__testimonials-brands div img:hover{
    filter: grayscale(0);
}

@media(max-width:450px){
    .app__testimonials-brands div{
        width: 70px;
        margin: 1rem;
    }
    
}


@media(max-width:800px) {
    .app__testimonial-item {
        width: 100%;
        flex-direction: column;
    
        }
}