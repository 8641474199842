.app__work-filter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 2rem 2rem;
   
}

.app__work-filter .app__work-filter-item{
    padding: 0.5rem 1rem;
    cursor: pointer;
    background: #fff;
    margin: 3px;
    color: #000;
    transition: all 0.3s ease;
    font-weight: 500;
    border-radius: 4px;
  
}

.app__work-filter-item:hover{
    background: var(--secondary-color);
    color: #fff;
}
.item-active{
    background: var(--secondary-color) !important;
    color: #fff !important;
}

.app__work-portfolio{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.app__work-item {
    width: 270px;
    flex-direction: column;
    background: #fff;
    color: #000;
    padding: 1rem;
    margin: 2rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}
.app__work-item:hover{
box-shadow: 0 0 25px rgba(0, 0,0, 0.2);
}

.app__work-img{
    width: 100%;
    position: relative;
    height: 230px;
}


.app__work-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.app__work-hover{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0,0, 0.5);
    border-radius: 0.5rem;
}

.app__work-hover div{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0,0, 0.5);;
    color: #fff;
    font-size: 1.5rem;
}

.app__work-content {
    width: 100%;
    position: relative;
    flex-direction: column;
}

.app__work-content h4{
    margin-top: 1rem;
}
.app__work-content .app__work-tags{
    position: absolute;
    background: #fff;
    top: -25px;
    border-radius: 10px;
    padding: 0.5rem 1rem;
}