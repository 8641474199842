#Home{
    position: relative;
    background: url("../../assets//bgIMG.png");
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
}

.app__header{
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: row;
    padding: 1rem 1rem 0;
}
.app__header-badge{
    display: flex;
    align-items: flex-end;
    justify-content:flex-end;
    flex-direction: column;
    width: 100%;
    /* margin-top: -20rem; */
}

 .app__header-badge .badge__cmp {
    padding: 1rem 2rem;
    border: var(--white-color);
    flex-direction: row;
    width: auto;
    border-radius: 15px;
    box-shadow: 0  0  20px rgba(0, 0, 0, 0.1);
}
.badge__cmp span{
    font-size: 2rem;
}

.tag__cmp{
    flex-direction: column;
    padding: 1rem 2rem;
    border:0px solid var(--white-color);

margin-top: 2rem;
    border-radius: 15px;
    box-shadow: 0  0  20px rgba(0, 0, 0, 0.1);
}

.app__header-circles{
    flex: 0.75;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;
    flex-direction: column;
}

.app__header-circles .circle-cmp{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0  0  20px rgba(0, 0, 0, 0.1);
    background: var(--white-color);
}

 .circle-cmp:nth-child(1){
    width: 100px !important;
    height: 100px;
   
}

.circle-cmp:nth-child(2){
    width: 150px !important;
    height: 150px;
    margin: 1.75rem;
   
}
.circle-cmp:nth-child(3){
    width: 70px !important;
    height: 70px;
   
}
.app__header-circles .circle-cmp img{
    width: 60%;
    height: 60%;
}
.app_header-img{
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    
    position: relative;
}
.app_header-img img{

width: 100%;
    z-index: 1;
    transform: scale(1.2);
   
}

.app__header .overly{
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 90%;
}









@media(min-width:2000px){
    .app__header{
     
        padding-top: 8rem;
    }
}

@media(max-width:1200px){
    .app__header{
     
        flex-direction: column;
    }
    .app__header-badge{

        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 0;
     
    }
    .app__header-circles{
        flex: 0.75;
       flex-wrap: wrap;
        width: 100%;
        margin-left: 0rem;
        flex-direction: row;
    }

}
@media(max-width:450px){
    .app__header{
     
        padding: 6rem 2rem 1rem;
    }
}