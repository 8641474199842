
.app__skills-container{
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
}
.app__skills-list{
flex: 1;
display: flex;
justify-content: flex-end;
align-items: center;
flex-wrap: wrap;
margin-right: 5rem;
}
.app__skills-item{
    flex-direction: column;
    text-align: center;
    margin: 1rem;
    transition: all 0.3s ease-in-out;
  
}


.app__skills-item div{
    width: 90px;
    height: 90px;
    background: #ffff;
    border-radius: 50%;
}
.app__skills-item div:hover{
   box-shadow: 0 0 25px #fef4f5 ;
}

.app__skills-item div img{
  height: 50%;
    width: 50%;
}


.app__works-exp{
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
}
.app__skills-item-exp{
    display: flex;
    width: 100%;
    margin: 1rem 0;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
}

.app__skills-year{
    margin-right: 3rem;
   

}
.app__skills-year p{
    color: var(--secondary-color);
}


@media(max-width:900px){

    .app__skills-year{
        margin-right: 1rem;
       
    
    }
    .app__works-exp{
       margin-top: 2rem;
    }
    .app__skills-container{
        width: 100%;

        flex-direction: column;
      
    }
    .app__skills-list{
      
        margin-right: 0rem;
        justify-content: center;
        align-items: center;
        }
        
    
}