.app__profiles{
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: flex-start;
margin-top: 2rem;
}

.app__profile-item{
    width: 190px;
    margin: 2rem;
}
.app__profiles img{
    width: 100%;
    height: 170px;
    object-fit: cover;
    border-radius: 15px;
}